<template>
    <div class="ipad-page">
        <div class="inner">
            <div class="display-section">
                <div class="toolbar">
                    <div class="filter">
                        <div
                            v-for="state in states"
                            :key="state.value"
                            @click="onFilterChange(state.value)"
                            :class="{ active: filterState === state.value }"
                        >
                            {{ state.label }}
                        </div>
                    </div>
                    <div class="search">
                        <el-input
                            v-model="searchKeyword"
                            placeholder="搜尋關鍵字"
                            clearable
                            :suffix-icon="
                                searchKeyword ? undefined : 'el-icon-search'
                            "
                            @change="onTextFilterChange"
                        ></el-input>
                    </div>
                </div>
                <SalesSelfCase
                    :data="list"
                    :on-link="onLink"
                    :on-edit="onEdit"
                    :on-delete="onDelete"
                    :on-preview="onPreview"
                    :on-submit="onSubmit"
                    :on-update="handleInfinite"
                >
                    <template v-slot:header>
                        <div>狀態</div>
                        <div>醫師</div>
                        <div class="auto-field">更新日期</div>
                    </template>
                </SalesSelfCase>
            </div>
            <div class="policy-box">
                <div
                    class="item reveal-information"
                    @click="onOpenRevealInformationPopup"
                >
                    羅氏揭露訊息
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SalesSelfCase from '@/components/Table/SalesSelfCase.vue';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
    components: { SalesSelfCase },
    data() {
        return {
            loading: false,
            searchKeyword: '',
            states: [
                {
                    label: 'All',
                    value: undefined,
                },
                {
                    label: '草稿',
                    value: 'draft',
                },
                {
                    label: '已儲存',
                    value: 'doctor-save',
                },
                {
                    label: '已送出',
                    value: 'doctor-send',
                },
                {
                    label: '待審核',
                    value: 'review',
                },
                {
                    label: '未通過',
                    value: 'reject',
                },
                {
                    label: '審核完畢',
                    value: 'approve',
                },
                {
                    label: '已上前端',
                    value: 'display',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            list: (state) => state.sales.caseList,
            filterState: (state) => state.sales.filterState.value,
            config: (state) => state.config.question,
        }),
    },
    destroyed() {
        this.clear();
    },
    methods: {
        ...mapMutations({
            updateGenerateLinkAndPasswordPopUpState:
                'popup/updateGenerateLinkAndPasswordPopUpState',

            updateConfirmationState: 'popup/updateConfirmationState',
            updateConfirmationText: 'popup/updateConfirmationText',
            updateConfirmationFunction: 'popup/updateConfirmationFunction',
            updateRevealInformationPopupState: 'popup/updateRevealInformationPopupState',

            setMode: 'form/setMode',

            updateFilterState: 'sales/updateFilterState',
            updateFilterText: 'sales/updateFilterText',
            increasePage: 'sales/increasePage',
            clear: 'sales/clear',
            setTarget: 'sales/setTarget',
        }),
        ...mapActions({
            apiClient: 'api/invoke',

            fetchQuery: 'sales/fetchQuery',
            updateFilter: 'sales/updateFilter',
            onSearch: 'sales/onSearchCase',
        }),
        onOpenRevealInformationPopup() {
            this.updateRevealInformationPopupState(true);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        async handleInfinite($state) {
            try {
                this.loading = true;
                this.fetchQuery();
                const RES = await this.onSearch();
                this.increasePage();
                if (RES) $state.loaded();
                else $state.complete();
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        onLink(item) {
            const DATA = {
                id: item.id,
                doctorName: item.doctorName,
                verifyCode: item.verifyCode,
                CTNumber: item.CTNumber,
            };
            this.setTarget(DATA);
            this.updateGenerateLinkAndPasswordPopUpState(true);
        },
        onEdit(index) {
            this.$router.push({
                name: 'Sales_Form',
                params: { id: index },
            });
        },
        onDelete(index) {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要刪除此案例嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(async () => {
                const PAYLOAD = {
                    category: 'Case',
                    function: 'delete',
                    successMsg: '案例已刪除',
                    data: index,
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            });
        },
        onPreview(index) {
            this.setMode('VIEW');
            this.$router.push({
                name: 'Sales_Form',
                params: { id: index },
            });
        },
        onSubmit(target) {
            this.updateConfirmationText({
                cancel: '取消',
                confirm: '確定',
                title: '確定要送出此案例嗎？',
            });
            this.updateConfirmationState(true);
            this.updateConfirmationFunction(() => this.validateSubmit(target));
        },
        async validateSubmit(target) {
            const ID = target.id;
            const DATA = target.data.data;
            let VALIDATE = true;
            let UnCompletion;
            if (!DATA) {
                VALIDATE = false;
            } else {
                this.config.forEach(page => {
                    page.forEach(item => {
                        if (item.rules && item.rules.required) {
                            if (item.depend) {
                                if (DATA[item.depend.fieldName].match(item.depend.rule)) {
                                    if (
                                        !DATA[item.fieldName] ||
                                        DATA[item.fieldName].length === 0
                                    ) {
                                        UnCompletion = item.label;
                                        VALIDATE = false;
                                    }
                                }
                            } else {
                                const noValue = !DATA[item.fieldName];
                                const emptyArray = !noValue && DATA[item.fieldName].length === 0;
                                if (noValue || emptyArray) {
                                    UnCompletion = item.label;
                                    VALIDATE = false;
                                }
                                if (!noValue && item.component === 'radio-select') {
                                    const fieldValue = DATA[item.fieldName];
                                    const comboTextNotFill = item.selections
                                        .filter(s => s.type === 'combo-text')
                                        .map(s => {
                                            console.log(fieldValue, s.value);
                                            const match = fieldValue.match(s.value.replace('{value}', '(.*)'));
                                            if (match && match[1].trim() === '') return true;
                                            return false;
                                        }).reduce((a, b) => a || b, false);
                                    if (comboTextNotFill) {
                                        UnCompletion = item.label;
                                        VALIDATE = false;
                                    }
                                }
                            }
                        }
                    });
                });
            }
            if (!VALIDATE) {
                this.$message({
                    message: UnCompletion
                        ? `${UnCompletion}尚未完成`
                        : '尚未儲存任何資料',
                    type: 'error',
                });
                this.onEdit(ID);
            } else {
                const PAYLOAD = {
                    category: 'Case',
                    function: 'review',
                    successMsg: '案例已送出',
                    data: ID,
                };
                await this.apiClient(PAYLOAD);
                this.updateFilter();
            }
        },
        onFilterChange(state) {
            if (this.loading) return;
            this.updateFilterState(state);
            this.updateFilter();
        },
        onTextFilterChange(text) {
            this.updateFilterText(text);
            this.updateFilter();
        },
    },
};
</script>
