<template>
    <div class="sales-self-case-data-table">
        <div class="table-header">
            <slot name="header" />
            <div></div>
        </div>
        <div
            v-for="(item, index) in data"
            :key="index"
            class="search-result-item"
        >
            <div class="state" :class="`${item.state}`">
                <span>{{ stateDisplay(item.state) }}</span>
            </div>
            <div>
                <span>{{ item.doctorName }}</span>
            </div>
            <div>
                <span>{{ formatDate(item.data.updatedAt) }}</span>
            </div>

            <button-wrapper
                v-if="checkState(item.state).deleteable"
                type="modal"
                color="danger"
                @click="onDelete(item.id)"
            >
                刪除
            </button-wrapper>
            <div v-else></div>
            <button-wrapper
                v-if="checkState(item.state).editable"
                type="modal"
                color="primary"
                @click="onLink(item)"
            >
                連結密碼
            </button-wrapper>
            <div v-else></div>
            <button-wrapper
                v-if="checkState(item.state).editable"
                type="modal"
                color="primary"
                @click="onEdit(item.id)"
            >
                編輯
            </button-wrapper>
            <div v-else></div>
            <button-wrapper
                v-if="checkState(item.state).editable"
                type="modal"
                color="primary"
                @click="onSubmit(item)"
            >
                送出
            </button-wrapper>
            <button-wrapper
                v-if="
                    false &&
                    !checkState(item.state).deleteable &&
                    !checkState(item.state).editable
                "
                :disabled="true"
                type="modal"
                color="primary"
                @click="onPreview"
            >
                瀏覽
            </button-wrapper>
        </div>
        <infinite-loading
            ref="infinite"
            :identifier="infiniteId"
            @infinite="onUpdate"
        ></infinite-loading>
    </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        onLink: {
            type: Function,
            default: () => {},
        },
        onEdit: {
            type: Function,
            default: () => {},
        },
        onDelete: {
            type: Function,
            default: () => {},
        },
        onPreview: {
            type: Function,
            default: () => {},
        },
        onSubmit: {
            type: Function,
            default: () => {},
        },
        onUpdate: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            multipleSelection: [],
            isIndeterminate: false,
            checkAll: false,
        };
    },
    computed: {
        ...mapState({
            infiniteId: (state) => state.sales.infiniteId,
        }),
    },
    methods: {
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm');
        },
        handleCheckAllChange(val) {
            if (val) {
                const TOTAL_DATA_LENGTH = this.data.length;
                const ALL_SELECTED = [];
                for (let i = 0; i < TOTAL_DATA_LENGTH; i += 1) ALL_SELECTED.push(i);
                this.multipleSelection = ALL_SELECTED;
            } else {
                this.multipleSelection = [];
            }
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange() {
            const checkedCount = this.multipleSelection.length;
            this.checkAll = checkedCount === this.data.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.data.length;
        },
        stateDisplay(state) {
            switch (state) {
                case 'draft':
                    return '草稿中';
                case 'doctor-save':
                    return '已儲存';
                case 'doctor-send':
                    return '已送出';
                case 'review':
                    return '待審核';
                case 'reject':
                    return '未通過';
                case 'approve':
                    return '審核完畢';
                case 'display':
                    return '已上前端';
                default:
                    return '';
            }
        },
        checkState(state) {
            switch (state) {
                case 'draft':
                case 'doctor-save':
                case 'doctor-send':
                    return { deleteable: true, editable: true };
                case 'reject':
                    return { deleteable: false, editable: true };
                case 'review':
                case 'approve':
                case 'display':
                default:
                    return { deleteable: false, editable: false };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.sales-self-case-data-table {
    position: relative;
    overflow: auto;
    height: calc(100% - 70px);
    > :last-child{
        padding-top: 20px;
    }
    .table-header {
        position: sticky;
        z-index: 1;
        top: 0;
        width: 100%;
        display: grid;
        grid-template-columns: 130px 120px auto 440px;
        > * {
            text-align: left;
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 5px;
        }
    }
    .search-result-item {
        transition: 0.3s;
        display: grid;
        grid-template-columns: 130px 120px auto 120px 120px 120px 120px;
        align-items: center;
        text-align: center;
        > * {
            padding: 20px 5px;
            text-align: left;
            text-overflow: ellipsis;
            word-break: keep-all;
            white-space: nowrap;
            overflow: hidden;
        }
        .state {
            width: 100px;
            height: 40px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
        }
        .auto-field {
            overflow: auto;
            min-width: 100px;
            display: flex;
            align-items: center;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}
</style>
